import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'

import { ProjectSlide } from '../ProjectSlide'
import { Project } from '../../lib/types'
import { animationConfig } from '../../utils/animationConfig'

interface Props {
  projects: Project[]
  initialIndex: number
  onSwiper: (swiper: SwiperClass) => void
  onSlideChange: (swiper: SwiperClass) => void
  onSlideChangeTransitionStart: (swiper: SwiperClass) => void
  onSlideChangeTransitionEnd: (swiper: SwiperClass) => void
  handleNavigateProject: (slug: string) => void
}

export const ProjectsCarousel: React.FC<Props> = ({
  projects,
  initialIndex,
  onSwiper,
  onSlideChange,
  onSlideChangeTransitionStart,
  onSlideChangeTransitionEnd,
  handleNavigateProject
}) => {
  return (
    <SwiperSlide>
      <Swiper
        speed={animationConfig.slideDuration}
        direction={'horizontal'}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        initialSlide={initialIndex}
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
        onSlideChangeTransitionStart={onSlideChangeTransitionStart}
        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
      >
        {projects.map((project, index) => {
          return (
            <SwiperSlide
              key={project.slug}
              className={`slide ${index === initialIndex ? `active` : ``}`}
            >
              <ProjectSlide
                project={project}
                handleNavigateProject={handleNavigateProject}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </SwiperSlide>
  )
}
