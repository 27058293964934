import React from 'react'
import styled from 'styled-components'

import { media } from '../../styles/utils'

const OG_WIDTH = 21
const OG_HEIGHT = 34

const ASPECT_RATIO = 21 / 34

const MOBILE_WITDH = 16.8
const MOBILE_HEIGHT = MOBILE_WITDH / ASPECT_RATIO


const Svg = styled.svg`
  cursor: pointer;

  width: ${p => p.scale * OG_WIDTH}px;
  height: ${p => p.scale * OG_HEIGHT}px;

  ${media.tablet`
    width: ${p => p.scale * MOBILE_WITDH}px;
    height: ${p => p.scale * MOBILE_HEIGHT}px;
  `}
`

const G = styled.g`
  transition: ${props => props.theme.transitions.navColor};
`

const ArrowHorizontal = ({ color, invert, scale = 0.7 }) => {
  return invert
    ?
      // left arrow
      <Svg scale={scale} width="21px" height="34px" viewBox="0 0 21 34">
        <G id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            id="Path"
            fill={color}
            transform="translate(16.970954, 16.970954) rotate(135.000000) translate(-16.970954, -16.970954) "
            d="M16.9543927,28.9710169 L4.9705264,28.9710803 L4.97089836,26.373911 L4.97127033,23.7767977 L14.4068286,23.7763886 L23.8423869,23.7759422 L23.8423869,14.3733846 L23.8423869,4.97082852 L26.4067005,4.97084191 L28.9710104,4.97085493 L28.9713824,16.9543361 C28.9713824,23.5452544 28.9641403,28.945318 28.9549751,28.9544869 C28.9458099,28.9636483 23.5455752,28.9711173 16.9544523,28.9710801 L16.9543927,28.9710169 Z"
          />
        </G>
      </Svg>
    :
      // right arrow
      <Svg scale={scale} width="21px" height="34px" viewBox="0 0 21 34">
        <G id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            id="Path"
            fill={color}
            transform="translate(3.970954, 16.970954) rotate(315.000000) translate(-3.970954, -16.970954)"
            d="M3.95439274,28.9710169 L-8.0294736,28.9710803 L-8.02910164,26.373911 L-8.02872967,23.7767977 L1.40682861,23.7763886 L10.8423869,23.7759422 L10.8423869,14.3733846 L10.8423869,4.97082852 L13.4067005,4.97084191 L15.9710104,4.97085493 L15.9713824,16.9543361 C15.9713824,23.5452544 15.9641403,28.945318 15.9549751,28.9544869 C15.9458099,28.9636483 10.5455752,28.9711173 3.95445226,28.9710801 L3.95439274,28.9710169 Z"
          />
        </G>
      </Svg>
}

export default ArrowHorizontal
