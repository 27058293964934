import React from 'react'

import { Arrow } from './Arrow'

interface Props {
  horizontalArrowsVisible: boolean
  downArrowVisible: boolean
  handlePrevious: () => void
  handleNext: () => void
  handleNavigateProject: () => void
}

export const NavigationArrows: React.FC<Props> = ({
  horizontalArrowsVisible,
  downArrowVisible,
  handlePrevious,
  handleNext,
  handleNavigateProject
}) => {
  return (
    <>
      <Arrow
        position={'left'}
        absolute
        text={`Previous Project`}
        show={horizontalArrowsVisible}
        onClick={handlePrevious}
        invert
        arrowStyle={{
          bottom: '50vh',
          transform: 'translate3d(0, 50%, 0)'
        }}
      />

      <Arrow
        position={'right'}
        absolute
        text={`Next Project`}
        show={horizontalArrowsVisible}
        onClick={handleNext}
        arrowStyle={{
          bottom: '50vh',
          transform: 'translate3d(0, 50%, 0)'
        }}
      />

      <Arrow
        text={'View Project'}
        absolute
        position={'bottom'}
        show={downArrowVisible}
        onClick={handleNavigateProject}
      />
    </>
  )
}
