import React from 'react'
import styled from 'styled-components'

import { media } from '../../styles/utils'

const Container = styled.span`
  overflow: hidden;
  ${media.tablet`
    display: none;
  `}
`

const Text = styled.p`
  opacity: 0;
  transition: all 0.35s;
  color: #fff;
`

interface Props {
  text: string
  position: string
}

export const ArrowText: React.FC<Props> = ({ text, position }) => {
  return (
    <Container>
      <Text
        className={`bold arrow-text-${position}`}
        style={{
          position: 'relative',
          bottom: '-0.15em'
        }}
      >
        {text}
      </Text>
    </Container>
  )
}
