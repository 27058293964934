import React from 'react'
import styled from 'styled-components'

import { media } from '../../styles/utils'

const OG_WIDTH = 34
const OG_HEIGHT = 21

const ASPECT_RATIO = 34 / 21

const MOBILE_WITDH = 27.2
const MOBILE_HEIGHT = MOBILE_WITDH / ASPECT_RATIO

const Svg = styled.svg`
  cursor: pointer;

  width: ${p => p.scale * OG_WIDTH}px;
  height: ${p => p.scale * OG_HEIGHT}px;

  ${media.tablet`
    width: ${p => p.scale * MOBILE_WITDH}px;
    height: ${p => p.scale * MOBILE_HEIGHT}px;
  `}
`

const G = styled.g`
  transition: ${props => props.theme.transitions.navColor};
`

const ArrowVertical = ({ color = '#000', scale = 0.7 }) => (
  <Svg scale={scale} width="34px" height="21px" viewBox="0 0 34 21">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        id="Path"
        fill={color}
        transform="translate(16.970954, 3.970954) rotate(45.000000) translate(-16.970954, -3.970954)"
        d="M16.9543927,15.9710169 L4.9705264,15.9710803 L4.97089836,13.373911 L4.97127033,10.7767977 L14.4068286,10.7763886 L23.8423869,10.7759422 L23.8423869,1.37338463 L23.8423869,-8.02917148 L26.4067005,-8.02915809 L28.9710104,-8.02914507 L28.9713824,3.95433614 C28.9713824,10.5452544 28.9641403,15.945318 28.9549751,15.9544869 C28.9458099,15.9636483 23.5455752,15.9711173 16.9544523,15.9710801 L16.9543927,15.9710169 Z"
      />
    </g>
  </Svg>
)

export default ArrowVertical
