import styled from 'styled-components'
import { media } from '../../styles/utils'

export const HeroTextContainer = styled.div`
  position: relative;
  width: fit-content;
  grid-row: 4;
  align-self: end;
  grid-column: 4 / 8;
  z-index: 1;
  text-align: left;

  ${media.large`
    grid-column: 3 / 9;
  `}

  ${media.phone`
    grid-column: 2 / 10;
  `}
`
