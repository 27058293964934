import React from 'react'
import styled from 'styled-components'

import { BackgroundImage } from './project-sections/ImageSection/BackgroundImage'
import { MasterGrid } from './layout/MasterGrid'
import { HeroTextContainer } from './layout/HeroTextContainer'
import { Project } from '../lib/types'

const Screen = styled(MasterGrid)`
  position: relative;
  width: 100%;
  height: 100vh;
`

interface Props {
  project: Project
  handleNavigateProject: (slug: string) => void
}

export const ProjectSlide: React.FC<Props> = ({
  project,
  handleNavigateProject
}) => {
  return (
    <Screen>
      <BackgroundImage
        image={project.heroBackground.gatsbyImageData}
        mobileImage={project.heroBackgroundMobile?.gatsbyImageData}
      />
      <HeroTextContainer>
        <a onClick={() => handleNavigateProject(project.slug)}>
          <span>
            <h1 className="font-align" style={{ color: '#FFF' }}>
              {project.name}
            </h1>
          </span>
          <span>
            <p className="subtitle font-align mb-0" style={{ color: '#FFF' }}>
              {project.tagline}
            </p>
          </span>
        </a>
      </HeroTextContainer>
    </Screen>
  )
}
