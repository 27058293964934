import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition } from 'react-spring/renderprops'

import { media } from '../../styles/utils'
import { navArrowSpring } from '../../utils/Springs'

import ArrowHorizontal from '../svg/ArrowHorizontal'
import ArrowVertical from '../svg/ArrowVertical'
import { ArrowText } from './ArrowText'

const Container = styled.div`
  display: flex;
  position: ${props => props.absolute ? `absolute` : `fixed`};
  ${props => props.position}: ${props => props.theme.sizes.slidePadding}px;
  z-index: ${p => p.theme.layers.navigationArrow};
  cursor: pointer;

  ${props => props.position === 'bottom'
    ? `
      left: 50%;
      transform: translateX(-50%);
    `
    : ``}

  ${media.tablet`
    ${props => props.position}: ${props => props.theme.sizes.slidePaddingMobile}px;
  `}
`

export const Arrow = ({ position, onClick, invert, show, absolute, text, top, arrowStyle }) => (

  <Transition
    config={navArrowSpring}
    items={show}
    from={{ opacity: 0 }}
    enter={{ opacity: 1 }}
    leave={{ opacity: 0 }}>

    {show => show && (style =>

      <Container
        top={top}
        style={{
          ...style,
          ...arrowStyle
        }}
        className={`arrow-container arrow-container-${position}`}
        onClick={onClick}
        position={position}
        absolute={absolute}>

        {text && position !== 'left'
          ? <ArrowText
            position={position}
            text={text}
          />
          : null}

        {position === 'bottom'
          ? <ArrowVertical color={'#FFF'} invert={invert} />
          : <ArrowHorizontal color={'#FFF'} invert={invert} />}

        {text && position === 'left'
          ? <ArrowText
            position={position}
            text={text}
          />
          : null}

      </Container>
    )}

  </Transition>

)

Arrow.propTypes = {
  show: PropTypes.bool,
  position: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  invert: PropTypes.bool
}
