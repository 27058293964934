import React from 'react'
import styled from 'styled-components'

const Container = styled.ul`
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 3px;
  z-index: ${props => props.theme.layers.slideIndicator};
  display: flex;
`
const Item = styled.li`
  flex-grow: 1;
  height: 100%;
  background: ${props => props.active ? '#FFF' : 'transparent'};
  transition: background ${p => p.theme.slides.transitionDuration} ${p => p.theme.slides.easing};
`

class SlideIndicator extends React.Component {
  _renderItems = () => {
    const { numberOfProjects, index } = this.props

    let items = []
    for (let i = 0; i < numberOfProjects; i++) {
      items.push(<Item active={i === index} key={i} />)
    }
    return items
  }

  render () {
    return (
      <Container>
        {this._renderItems()}
      </Container>
    )
  }
}

export default SlideIndicator
