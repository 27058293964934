// TODO: use https://stackoverflow.com/questions/57188179/show-overlay-only-once-on-page-entrance-not-route-change-howto
export const removePreloader = () => {
  const preloader = document.getElementById('preloader')
  if (preloader) {
    preloader.style.opacity = '0'
    setTimeout(() => {
      preloader.remove()
    }, 1000)
  }
}
